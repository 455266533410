//React
import { useEffect, useState } from "react";
// Anchor Components
import {
  TableV2,
} from "@anchor/react-components/dist/lib/components";
import { DtcoModelData } from "../../DtcoOverviewPage/DtcoModel";
import { CharteringManagerValidationModel } from "./CharteringManagerValidationModel";

interface CharteringManagerValidationTableProps {
  tableData: DtcoModelData;
}

const CharteringManagerValidationTable = ({
  tableData,
}: CharteringManagerValidationTableProps) => {

  const [data, setData] = useState<CharteringManagerValidationModel[]>([]);
  const getCellValueCalculation = (input1, input2) => {
    if (input1 === null || input2 === undefined || input2 === null || input2 === 0 || input2 === 0) {
      return 0;
    }
    return (input1 / input2);
  }
  //Function to handle null and undefined values
  const getCellValue = (input) => {
    if (input === null || input === undefined) {
      return 0;
    }
    return input;
  }
  useEffect(() => {
    if (tableData !== undefined || tableData !== null) {
      const validationRes = {
        serviceCode: tableData?.serviceCode,
        vesselName: tableData?.vesselName,
        slotCostPerDay: getCellValueCalculation(tableData?.slotCost, 365),
        tcCostPerDay: getCellValue(tableData?.tcd),
        fuelConsPerDay: getCellValueCalculation(tableData?.consupmtionInTons, tableData?.roundTripDays),
        fuelCostPerDay: getCellValueCalculation(tableData?.consumptionInUSD, tableData?.roundTripDays),
        intakeTEU: getCellValue(tableData?.bhCapturaIntake) + getCellValue(tableData?.hhCapturaIntake),
        totalCostPerDay: 0,
      }
      const res: CharteringManagerValidationModel[] = [];
      res.push(validationRes);
      setData(res);
    }
  }
    , [tableData]);

  const getCellNameValueDisplay = (input) => {
    if (input() === 0 || input() === null) {
      return 0;
    }
    return input().toLocaleString();
  }
  const displayImpactTableColumn = [
    {
      id: "serviceCode",
      accessorKey: "serviceCode",
      header: "Service",
      size: 70,
      enableSorting: false,
    },
    {
      id: "vesselName",
      accessorKey: "vesselName",
      header: "Vessel",
      enableSorting: false,
      size: 140,
    },
    {
      id: "slotCostPerDay",
      accessorKey: "slotCostPerDay",
      title: "Slot cost per day($)",
      header: () => <div title="Slot cost per day($)">Slot cost per day($)</div>,
      size: 100,
      enableSorting: false,

    },
    {
      id: "tcCostPerDay",
      accessorKey: "tcCostPerDay",
      header: () => <div title="TC cost per day($)">{"TC cost / day (TCD) ($)"}</div>,
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValueDisplay(getValue),
    },
    {
      id: "fuelConsPerDay",
      accessorKey: "fuelConsPerDay",
      header: () => <div title="Fuel Cons per day (MT)">{"Fuel Cons per day (MT)"}</div>,
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValueDisplay(getValue),
    },
    {
      id: "fuelCostPerDay",
      accessorKey: "fuelCostPerDay",
      header: () => <div title="Fuel Cost per day ($)">{"Fuel Cost per day ($)"}</div>,
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValueDisplay(getValue),
    },

    {
      id: "intakeTEU",
      accessorKey: "intakeTEU",
      header: () => <div title="Intake (TEU)">{"Intake (TEU)"}</div>,
      size: 70,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValueDisplay(getValue),
    },
    {
      id: "totalCostPerDay",
      accessorKey: "totalCostPerDay",
      header: () => <div title="Total Cost per day ($)">{"Total Cost per day ($)"}</div>,
      size: 100,
      enableSorting: false,
      cell: ({ getValue }) => getCellNameValueDisplay(getValue),
    },
  ];

  return (
    <div >
      <TableV2
        columnData={displayImpactTableColumn}
        defaultData={data}
        stickyHeader={true}
        maxHeight={260}
      />
    </div>
  );
};
export default CharteringManagerValidationTable;
