// React
import React from "react";

const SideBarData = [
  {
    title: "Home",
    path: "/",
    icon: "house",
    cName: "nav-text",
    page: "cep",
    id: "menu-dashboardPage",
    key: 1
  },
  {
    title: "Simulation Request",
    path: "/SimulationRequest",
    icon: "light-bulb",
    cName: "nav-text",
    key: 2,
    subNav: [
      {
        title: "Simulation Request Data",
        path: "/SimulationRequest/SimulationRequestResult/:requestId",
      }
    ]
  },
  {
    title: "DTCO Request Page",
    path: "/RequestPage",
    icon: "warehouse",
    cName: "nav-text",
    key: 3,
    subNav: [
      {
        title: "Create Proposal",
        path: "/RequestPage/DtcoOverviewPage/:requestId",
      }
    ]
  },
  {
    title: "Vessel Selection Tool",
    path: "/CharterRequestPage",
    icon: "vessel-front",
    cName: "nav-text",
    key: 4,
    subNav: [
      {
        title: "Initiate Comparison Proposal",
        path: "/CharterRequestPage/CharterOverviewPage/:requestId",
      },
      {
        title: "Save Comparison Proposal",
        path: "/CharterRequestPage/SaveValidationScreen/:requestId",
      }
    ]
  }
];
export default SideBarData;
